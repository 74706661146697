
import { Vue, Component, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { getFeedback, addFeedback } from '@/api/tfse';

@Component({
  components: {
  },
})

export default class extends Vue {
  mounted() {
	this.getFeedback();
	if (this.$route.query.tfseDate) {
			this.tfseDate = this.$route.query.tfseDate;
	} else {
		this.$router.replace({ ...this.$router, query: { ...this.$route.query, tfseDate: this.tfseDate } });
	}
	// if (this.$route.query.tab) this.type = this.$route.query.tab;
  }

  private tfseDate: any = this.$route.query.tfseDate ? this.$route.query.tfseDate : moment().format('YYYY-MM-DD');

  private type: any = 'tfse';

  private listQuery = {
	searchDate: this.tfseDate,
	}

  private selfFeedback: any = {
	selfFeedbackDate: '',
	question1: '',
	question2: '',
	question3: '',
  }

  private getFeedback() {
	getFeedback(this.listQuery).then((res) => {
		this.selfFeedback.question1 = res.data.question1;
		this.selfFeedback.question2 = res.data.question2;
		this.selfFeedback.question3 = res.data.question3;
	});
  }

  private handleAddDay(days: number) {
    this.tfseDate = moment(this.tfseDate, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD');
	this.listQuery.searchDate = this.tfseDate;
    this.$router.replace({ ...this.$router, query: { tfseDate: this.tfseDate } });
	this.getFeedback();
  }

  private addFeedback() {
	this.selfFeedback.selfFeedbackDate = this.tfseDate;
      addFeedback(this.selfFeedback).then(() => {
        this.$message.info('정상적으로 수정되었습니다.');
		this.$router.push({ name: 'SelfFeedback' });
      });
  }
}
